import React, { useState } from "react";
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";

export default function FilesTable() {
  const [sort, setSort] = useState<"name" | "size">("name");
  
  const {
    data,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [
      "uploadedFiles",
    ],
    async () => {
        try {
            const files = await fetch("www.google.com")
            const fakeFiles = [
                {
                    file_url: "",
                    file_name: "file1",
                    file_size: "3.2 MB"
                },
                {
                    file_url: "",
                    file_name: "file2",
                    file_size: "4.1 MB"
                },
            ]
            const fakeRes = {
                files: fakeFiles,
            }
            return fakeRes;
        } catch (error) {
            throw error;
        }
    },
    {
      enabled: true,
      retry: false,
      meta: {
        errorMessage: "Error getting uploaded files",
      },
    }
  );
  
  

  return (
    <Container>
        <div>
            SEARCHBAR
        </div>
        <p>Uploaded Files</p>
        <div>
            <Row>
                <p>File Name</p>
                <p>File Size</p>
                <span/>
            </Row>
            <div>
                {
                    data?.files.map((file) => {
                        return (
                            <Row>
                                <p>{file.file_name}</p>
                                <p>{file.file_size}</p>
                                <div>
                                    <button>View</button>
                                    <button>Download</button>
                                </div>
                            </Row>
                        )
                    })
                }
            </div>
        </div>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;