import axios from 'axios';

type FetchEmployeesBody = {
    roster_type: string,
    filter_type?: string | null,
    filter_value?: string | null,
}

type FetchEmployeesResponse = {
  values: any[],
}

type FetchOptionsResponse = {
  teams: any[],
  markets: any[],
  markets_filter: any[],
  departments: any[],
  active_status: any[],
  departments_ui: any[],
}

type FetchChangeOrderResponse = {
  id: number,
  momentum_id: string,
  created_on: string,
  actioned_on: string | null,
  requested_by: string,
  actioned_by: string | null,
  employee_info: Employee,
  actions: {
    type: string,
    action: string,
    selection: string,
    old_selection: string,
  }[],
}

const fetchEmployees = async (filterOpt?: string | null, filterValue?: string | null) => {

    const employeesEnpoint = `${process.env.REACT_APP_BASE_URL}/roster`;
    let body: FetchEmployeesBody = { roster_type: "sales" }

    if(filterOpt && filterValue) {
      Object.assign(body, { filter_type: filterOpt });
      Object.assign(body, { filter_value: filterValue });
    }

    console.log("body", body)
    const response = await axios.post(employeesEnpoint, body)
    .then(function ({ data }: { data: FetchEmployeesResponse }) {return data});

    return response;
};


const fetchOptions = async () => {
  const fetchOptionsEndpoint = `${process.env.REACT_APP_BASE_URL}/get_options`;

  const response = await axios.get(fetchOptionsEndpoint)
  .then(function ({ data }: { data: FetchOptionsResponse }) {return data});
  console.log("response")

  // replace any null department_grouping values with "Other"
  // for sorting purposes
  const newDeptGroupings = response?.departments_ui?.map((dept: any) => {
    const newDept = { ...dept };
    if(!newDept.department_grouping) {
      newDept.department_grouping = "Other";
    }
    return newDept;
  });
  
  // sort department_grouping values in reverse order
  const sortedDepartmentGroupings = newDeptGroupings.sort((a, b) => {
    // Move null values to the end of the array
    if (a.department_grouping === null) return 1;
    if (b.department_grouping === null) return -1;

    // Compare department_grouping values in reverse order
    if (a.department_grouping > b.department_grouping) return -1;
    if (a.department_grouping < b.department_grouping) return 1;
    return 0;
  });

  const res = {
      teams: response?.teams,
      markets: response?.markets,
      markets_filter: response?.markets_filter,
      departments: response?.departments,
      active_status: response?.active_status,
      department_groupings: sortedDepartmentGroupings,
  }

  return res;
};

const postUpdateEmployeesRequest = async (body: any) => {
  const updateEmployeesEndpoint = `${process.env.REACT_APP_BASE_URL}/update`;
  console.log("body", body);

  const response = await axios
    .post(updateEmployeesEndpoint, body)
    .then(({ data }) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(data);
        }, 3000);
      });
    });

  return response;
};

const fetchChangeOrder = async (changeId: string) => {
  const fetchChangeOrderEnpoint = `${process.env.REACT_APP_BASE_URL}/get_pending?pending_id=${changeId}`;
  const response = await axios.get(
    fetchChangeOrderEnpoint
  ).then(function ({ data }: { data: FetchChangeOrderResponse }) {return data});
  return response;
};

type ConfirmChangeOrderBody = {
  pending_id: number,
  actioned_by: string,
}

const confirmChangeOrder = async (body: ConfirmChangeOrderBody) => {
  console.log("bldy", body)
  const confirmChangeOrderEndpoint = `${process.env.REACT_APP_BASE_URL}/update_pending`;
  const response = await axios.post(
    confirmChangeOrderEndpoint,
    body
  )
  return response
};

const fetchTeams = async () => {
  const fakeTeams = [
  {
    id: 1,
    key: "team1",
    label: "Team 1",
    team_name: "Team 1",
    market: "San Antonio",
    team_lead: "Papa Solar",
  },
  {
    id: 2,
    key: "team2",
    label: "Team 2",
    team_name: "Team 2",
    market: "San Antonio",
    team_lead: "Papa Solar",

  },
  {
    id: 3,
    key: "team3",
    label: "Team 3",
    team_name: "Team 3",
    market: "San Antonio",
    team_lead: "Papa Solar",
  },
];

  return {
    values: fakeTeams
  };
};

const fetchEmployeesForTeams = async (
) => {
  const employeesEnpoint = `${process.env.REACT_APP_BASE_URL}/roster`;
  let body: FetchEmployeesBody = { roster_type: "sales" };

  console.log("body", body);
  const response = await axios
    .post(employeesEnpoint, body)
    .then(function ({ data }: { data: FetchEmployeesResponse }) {
      const newData = data.values.map((employee, idx) => {
        const newEmployee: any = { ...employee };
        if(idx < 10) {
          newEmployee.team_id = 1;
          newEmployee.team_name = "Team 1";
          newEmployee.team_lead = "Papa Solar";
        } else if(idx < 20) {
          newEmployee.team_id = 2;
          newEmployee.team_name = "Team 2";
          newEmployee.team_lead = "Papa Solar";
        } else if(idx < 30) {
          newEmployee.team_id = 3;
          newEmployee.team_name = "Team 3";
          newEmployee.team_lead = "Papa Solar";
        }
        return newEmployee;
      });
      return {
        values: newData
      };
    });

  return response;
};

const fakeTeamLeaders = [
  {
    id: 1,
    label: "Papa Solar",
    team: "Team 1",
    name: "Papa Solar",
  },
  {
    id: 2,
    label: "John Smith",
    team: "Team 2",
    name: "John Smith",
  },
  {
    id: 3,
    label: "Jane Doe",
    team: "Team 3",
    name: "Jane Doe",
  }
];

const fetchTeamLeads = async () => {
  return {
    values: fakeTeamLeaders
  };
};

export { 
  fetchEmployees,
  fetchOptions,
  fetchChangeOrder,
  confirmChangeOrder,
  postUpdateEmployeesRequest,
  fetchTeams,
  fetchEmployeesForTeams,
  fetchTeamLeads,
};

