import styled from "@emotion/styled";
import { COLORS } from "../../Theme";
import { Button } from "../../GlobalComponents";
import { PropagateLoader } from "react-spinners";
import { limitString } from "../../Util";

export default function ChangesGrid({
  changes,
  onConfirm,
  requester,
  isLoading,
}: {
  changes: Change[] | undefined;
  onConfirm: () => void;
  requester: string;
  isLoading: boolean;
}) {
  const cleanSnakeCase = (str: string) => {
    return str
      ?.replace(/_/g, " ")
      .replace(/^(.)|\s+(.)/g, (c: string) => c.toUpperCase());
  };

  return (
    <Container>
      <div id="header">
        <p id="title" style={{ color: COLORS.PRIMARY }}>
          Requested Changes
        </p>
        <p id="title">Requested by: {requester}</p>
      </div>
      <div id="table-container">
        <div id="table-header">
          <div style={{ width: "20%" }} className="table-cell">
            <p>Change</p>
          </div>
          <Divider />
          <div className="table-cell">
            <p>Old Value</p>
          </div>
          <Divider />
          <div className="table-cell">
            <p>New Value</p>
          </div>
        </div>
        {changes?.map((change, index) => {
          return (
            <div className="table-row" key={index}>
              <div
                style={{ width: "20%", backgroundColor: COLORS.LIGHT_GRAY }}
                className="table-cell"
              >
                <p>{cleanSnakeCase(change.action)}</p>
              </div>
              <Divider />
              <div className="table-cell">
                <p style={{ color: COLORS.GRAY }} className="table-cell">
                  {limitString(change.old_selection || "", 25)}
                </p>
              </div>
              <Divider />
              <div className="table-cell">
                <p className="table-cell">{change.selection}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="buttons-container">
        {
            isLoading ?
            <PropagateLoader color={COLORS.PRIMARY} />
            :
            <Button onClick={onConfirm}>Confirm Changes</Button>
        }
      </div>
    </Container>
  );
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    color: #585858;
    p {
        color: #000;
        font-family: Lato, sans-serif;
    }
    #title {
        color: #000;
        font-family: "Gotham Bold";
        color ${COLORS.PRIMARY};
    }
    #header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 0px;
    }
    #table-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid ${COLORS.GRAY};
        border-radius: 5px;
    }
    #table-header {
        display: flex;
        justify-content: space-around;
        width: 100%;
        border-bottom: 1px solid ${COLORS.GRAY};
        background-color: ${COLORS.LIGHT_GRAY};
    }
    .table-header-text {
        font-family: Gotham Bold;
    }
    .table-row {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        border-bottom: 1px solid ${COLORS.GRAY};
        height: 40px;
        background-color: #fff;
    }
    .table-cell {
        width: 40%;
        height: 100%;
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .buttons-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
 `;

 const Divider = styled.div`
    width: 1px; 
    height: 100%;
    background-color: ${COLORS.GRAY};
`;