import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { COLORS } from "../../../Theme";
import { Button } from "../../../GlobalComponents";
import FilterSelect from "../Components/FilterSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDebounce } from "use-debounce";
import { useQuery } from "@tanstack/react-query";
import { fetchEmployees, fetchOptions, fetchTeamLeads } from "../../api";
import { Autocomplete, TextField } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  height: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  color: COLORS.PRIMARY,
  padding: "30px 0px",
};

export default function CreateTeamModal({
  open,
  setOpen,
  team,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  team?: any;
}) {

  const [teamName, setTeamName] = useState(team?.team_name || "");
  const [teamLead, setTeamLead] = useState<Employee | null>(team?.team_lead || null);
  const [teamMarket, setTeamMarket] = useState(team?.market || "");
  const [nameSearch, setNameSearch] = useState("");
  const [nameSearchDebounce] = useDebounce(nameSearch, 500);

  console.log("team", team);

  useEffect(() => {
    console.log("name search debounce", nameSearchDebounce);
  }, [nameSearchDebounce]);

  const {
    data: employeesData,
    error: employeesError,
    isLoading: employeesLoading,
    refetch: employeesRefetch,
  } = useQuery({
    queryKey: ["employees", nameSearchDebounce],
    queryFn: () => fetchEmployees("name", nameSearchDebounce),
    enabled: true,
    retry: false,
  });

  const {
    data: teamLeadsData,
    error: teamLeadsError,
    isLoading: teamLeadsLoading,
    refetch: teamLeadsRefetch,
  } = useQuery({
    queryKey: ["teamLeads"],
    queryFn: () => fetchTeamLeads(),
    enabled: true,
    retry: false,
  });

  const {
    data: optionsData,
    error: optionsError,
    isLoading: optionsLoading,
    refetch: optionsRefetch,
  } = useQuery({
    queryKey: ["options"],
    queryFn: () => fetchOptions(),
    enabled: true,
    retry: false,
  });

  const onSave = () => {
    const body = {
      team_name: teamName,
      team_lead: teamLead,
      team_market: teamMarket,
    }

    console.log("body", body);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {team ? `Edit ${team.team_name}` : "Create New Team"}
        </Typography>
        <ActionsContainer>
          <div>
            <p>{team ? "Change" : ""} Team Name</p>
            <input
              className="team-name-input"
              placeholder="Enter Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <p>Market</p>
            <FilterSelect
              label="Select Market"
              options={optionsData?.markets || []}
              value={team?.market || teamMarket}
              onSelect={(e: string) => {
                setTeamMarket(e);
              }}
            />
            <p>Team Lead</p>
            <p>{team?.team_lead || "Choose a Team Lead"}</p>
          </div>
          {/* <SearchContainer>
            <p>Choose Team Lead</p>
            <input
              className="search-input"
              placeholder="Search Team Lead"
              onChange={(e) => setNameSearch(e.target.value)}
            />
            <div className="search-results">
              {nameSearchDebounce && employeesData?.values?.map((emp: Employee) => (
                <div className="search-result" onClick={() => setTeamLead(emp)}>
                  <p>{emp?.Name}</p>
                  <p>{emp?.Office_Code}</p>
                  <p>{emp?.Job_Title}</p>
                  <p>{emp?.team_name}</p>
                </div>
              ))}
            </div>
          </SearchContainer> */}
          <Autocomplete
            options={teamLeadsData?.values || []}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            // onChange={(e: any) => {
            //   const optionIndex = e.target.dataset.optionIndex;
            //   // we find the titles for the selected department
            //   const titles =
            //     actionSelections?.department_groupings[optionIndex]
            //       ?.job_titles || [];
            //   setAvailablePositions(titles);

            //   // get the optionIndex from the even target dataset
            //   // set the selection to the value of department_groupings
            //   // of the optionIndex
            //   onActionSelect(
            //     "department",
            //     actionSelections?.department_groupings[optionIndex]?.value || ""
            //   );
            // }}
            // groupBy={(option: any) => option.department_grouping}
            renderInput={(params) => (
              <TextField {...params} label="Search Team Leaders" />
            )}
            sx={{
              width: 210,
              height: 34,
              "& .MuiOutlinedInput-root": {
                height: 34,
                padding: "0 4px 7.5px 5px",
                backgroundColor: COLORS.LIGHT_GRAY,
              },
              // "& .MuiInputLabel-root": {
              //   top: "-25%",
              // },
            }}
          />
        </ActionsContainer>
        <SaveContainer>
          <Button
            onClick={() => {
              onClose();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </SaveContainer>
      </Box>
    </Modal>
  );
}

const ActionsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 80%;
  height: 80%;
  justify-content: space-between;
  align-items: flex-start;

  .team-name-input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 3px 7.5px;
    margin-top: 5px;
  }
  p {
    font-family: "Gotham Bold"
  }
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  .search-input {
    width: 100%;
    height: 50px;
    border-radius: 10px 10px 0 0;
    border: 1px solid gray;
    padding: 3px 7.5px;

    &:focus {
      outline: none;
    }
  }

  .search-results {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid gray;
    border-radius: 0 0 10px 10px;
    padding: 5px;
    padding-left: 10px;
  }

  .search-result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 95%;
    height: 200px;
    border-radius: 10px;
    border: 1px solid ${COLORS.PRIMARY};
    margin: 10px 0;
    padding: 5px;
    cursor: pointer;
    font-size: 13px;
    cursor: pointer;
  }
`;
