import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import { ReactComponent as CloudIcon } from "../../assets/CloudIcon.svg";

const allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.ms-excel.sheet.binary.macroenabled.12",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.addin.macroenabled.12",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.addin.macroenabled.12",
    "application/vnd.ms-excel.sheet.binary.macroenabled.12",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.addin.macroenabled.12",
]

export default function COADFileDrop({ setFile, setFileError }: { setFile: any, setFileError: any }) {
  // drag state
  const [dragActive, setDragActive] = useState<boolean>(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    //@ts-ignore
    inputRef.current.click();
  };

  const handleFiles = (files: any) => {
    setFile(null);
    console.log("files", files);
    const newFile = files[0];
    if (!allowedFileTypes.includes(newFile.type)) {
        setFileError("File type not allowed");
        return;
    }
    setFileError(null);
    setFile(files[0]);
  };

  return (
    <Form
    id="form-file-upload"
    onDragEnter={handleDrag}
    onSubmit={(e) => e.preventDefault()}
    >
        <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
        />
        <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
        >
            <div>
              <CloudIcon />
              <p>Drag & Drop File Here<br/>-OR-</p>
              <button className="upload-button" onClick={onButtonClick}>
                Browse Files
              </button>
            </div>
        </label>
        {dragActive && (
            <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            ></div>
        )}
    </Form>
  );
}

const Form = styled.form`
  height: 16rem;
  width: 20rem;
  max-width: 100%;
  text-align: center;
  position: relative;

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #33ccff;
    background-color: #f6faff;
    cursor: pointer;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .upload-button {
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;