import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
`;

export default Wrapper;
