import React, { createContext, useState, useEffect } from "react";

interface ContextProps {
  selectedEmployees: any[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<any[]>>;
  selectionModel: any[];
  setSelectionModel: React.Dispatch<React.SetStateAction<any[]>>;
  lastPage: number;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
}

const Context = createContext<ContextProps>({} as ContextProps);

export const ContextProvider = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [lastPage, setLastPage] = useState<number>(0);

  useEffect(() => {
    console.log("selectedEmployees", selectedEmployees);
  }, [selectedEmployees]);


  return (
    <Context.Provider
      value={{
        selectedEmployees,
        setSelectedEmployees,
        selectionModel,
        setSelectionModel,
        lastPage,
        setLastPage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
