import React from "react";
import { useQuery } from "@tanstack/react-query";

const checkFileStatus = async () => {
  try {
    const status = await fetch("www.google.com");
    const fakeStatus = {
      status: "complete",
    };

    return fakeStatus;
  } catch (error) {
    throw error;
  }
};

export default function useFileStatusQuery() {
  const { data, error, isLoading, refetch } = useQuery(
    ["checkFileStatus"],
    checkFileStatus,
    {
      enabled: true,
      retry: false,
      refetchInterval: 3000,
      meta: {
        errorMessage: "Error getting file status",
      },
      onSuccess: (data) => {
        console.log("file status data", data);
      }
    }
  );

  return {
    data,
    error,
    refetch,
    isLoading,
    status: data?.status,
  };
}
