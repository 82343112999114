import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { COLORS } from "../../Theme";
import { PropagateLoader } from "react-spinners";

// styled components
import { Container } from "../Styled";

// assets
import { ReactComponent as BackArrow } from "../../assets/ArrowGray.svg";
import { ReactComponent as PersonIcon } from "../../assets/PersonIcon.svg";

// utils
import { limitString } from "../../Util";


export default function EmployeeInfo({
  employee,
  children,
  headerText="Employee Details",
  footer,
  active_status,
  showBackButton=true,
  isLoading,
  error,
  errorMessage="",
}: {
  employee: any;
  children: React.ReactNode;
  headerText: string;
  footer?: React.ReactNode;
  active_status: string;
  showBackButton?: boolean;
  isLoading?: boolean;
  error?: boolean;
  errorMessage?: string;
}) {
  const navigate = useNavigate();

  const [showEmailPopper, setShowEmailPopper] = useState<boolean>(false);

  const goBack = () => {
    navigate("/roster/employees");
  };

  return (
    <Wrapper>
      <Container>
        {showBackButton && (
          <BackButton onClick={goBack}>
            <BackArrow />
            <p>Back to Roster</p>
          </BackButton>
        )}
        {
          error && (
            <div style={{color: "red", fontSize: 16, marginBottom: 10}}>
              {errorMessage || "An error has occurred."}
            </div>
          )
        }
        <div style={{ marginBottom: 10 }}>
          <p id="title">{headerText}</p>
        </div>
        <EmployeeWrapper>
          <EmployeeCard status={employee?.Active_status}>
            <PersonIcon width={75} height={75} />
            <div>
              <p style={{ fontWeight: "bold" }}>{employee?.Name}</p>
              <p
                onMouseEnter={() => setShowEmailPopper(true)}
                onMouseLeave={() => setShowEmailPopper(false)}
              >
                {employee?.Email?.includes("dummy") || !employee?.Email
                  ? "-"
                  : limitString(employee?.Email, 25)}
              </p>
              {showEmailPopper && (
                <p
                  className="email-popover"
                  onMouseLeave={() => setShowEmailPopper(false)}
                >
                  {employee?.Email}
                </p>
              )}
              <p>{employee?.Office_Code || ""}</p>
            </div>
            <p id="status">{active_status}</p>
          </EmployeeCard>
          <div id="right-container">
            <div id="info-table">
              <div className="info-row">
                <p>Momentum ID:</p>
                <p>COAD ID:</p>
                <p>Paylocity ID:</p>
                <p>Current Title:</p>
              </div>
              <div className="info-row">
                <p className="info-text">{employee?.Momentum_id}</p>
                <p className="info-text">{employee?.Coad_Id}</p>
                <p className="info-text">{employee?.Pay_id}</p>
                <p className="info-text">{employee?.Job_Title || "-"}</p>
              </div>
              <span className="divider" />
              <div className="info-row">
                <p>Current Team:</p>
                <p>Team Leader:</p>
                <p>Hire Date:</p>
                <p>Department:</p>
              </div>
              <div className="info-row">
                <p className="info-text">{employee?.team_name || "-"}</p>
                <p className="info-text">
                  {!!employee?.team_leader_mid
                    ? employee?.team_leader_name
                    : "-"}
                </p>
                <p className="info-text">
                  {new Date(employee?.Hire_Date).toLocaleDateString() || "-"}
                </p>
                <p className="info-text">{employee?.Dept_Name || "-"}</p>
              </div>
            </div>
            <div id="children-container">{children}</div>
            {footer && isLoading ? (
              <div style={{ height: 50 }}>
                <PropagateLoader color={COLORS.PRIMARY} />
              </div>
            ) : (
              footer
            )}
          </div>
        </EmployeeWrapper>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
//   height: 75%;
  align-self: flex-start;
  margin-top: 40px;
  margin-right: 40px;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;
  width: 150px;
`;

const EmployeeWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  #right-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  #info-table {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 30px;
    align-items: center;
  }
  .divider {
    width: 95%;
    height: 1px;
    background-color: #d5d5d5;
    margin-top: 20px;
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    p {
      width: 25%;
      text-align: left;
      font-size: 14px;
      color: #000;
      padding-left: 15px;
    }
  }

  .info-text {
    font-weight: 600;
  }

  #footer {
    display: flex;
    justify-content: flex-end;
  }

  #children-container {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    width: 90%;
    // height: 175px;
    flex-wrap: wrap;
    // background-color: red;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .info-row:p {
    text-align: center;
  }

  #team-select-container {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
`;

// const Input = styled.input`
//   width: 200px;
//   height: 25px;
//   border-radius: 5px;
//   border: 1px solid gray;
// `;

const EmployeeCard = ({
  status,
  children,
}: {
  status?: string;
  children: React.ReactNode;
}) => {
  const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid ${COLORS.PRIMARY};
    width: 235px;
    height: 215px;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    font-size: 12px;
    position: relative;

    .email-popover {
      min-width: 120%;
      height: 30px;
      background-color: #fff;
      border-radius: 5px;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.15);
      padding: 0 10px;
      font-size: 12px;
      font-family: "Gotham Bold";
    }

    p {
      color: #000;
      text-align: center;
      line-height: 10px;
    }

    #status {
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 600;x
      color: ${
        status === "A" ? "#008019" : status === "T" ? "#C31313" : "#FFA500"
      };
      background-color: ${
        status === "A" ? "#D1F7D5" : status === "T" ? "#FF0000" : "#FFF5E5"
      };
    }
  `;

  return <Card>{children}</Card>;
};
