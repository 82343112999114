import { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";
import { PropagateLoader } from "react-spinners";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUpdateEmployeesRequest } from "../../../api";
import AuthContext from "../../../../Auth/AuthContext";

// mui
import {
  Box,
  Typography,
  Modal,
  Autocomplete,
  TextField,
} from "@mui/material";

// components
import { Button } from "../../../../GlobalComponents";
import FilterSelect from "../../Components/FilterSelect";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  color: COLORS.PRIMARY,
  padding: "40px 0px",
};

export default function ActionsModal({
  open,
  setOpen,
  actionSelections={},
  selectedEmployees=[],
  onSuccess,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  actionSelections: any;
  selectedEmployees: any[];
  onSuccess: () => void;
}) {
  const [selectedChanges, setSelectedChanges] = useState<any[]>([]);
  const [availablePositions, setAvailablePositions] = useState<any[]>([]);
  const { azureAccount } = useContext(AuthContext);

  const { mutate, isLoading } = useMutation(postUpdateEmployeesRequest,
    {
      onSuccess: () => {
        onClose();
        onSuccess();
      },
      onError: () => {
        alert("There was an error updating the employees");
      }
    }
  );


  console.log("action selections", actionSelections)

  const onActionSelect = (action: string, selection: string, changeKey: string) => {

    if (!selection) {
      // if selection is "" we remove the action from the selected changes
      const newSelectedChanges = [...selectedChanges];
      const index = newSelectedChanges.findIndex(
        (change) => change.action === action
      );
      if (index > -1) {
        newSelectedChanges.splice(index, 1);
        setSelectedChanges(newSelectedChanges);
      }

      if (action === "department") {
        // remove title from selected options
        setSelectedChanges((prev) => {
          return prev.filter((change) => change.action !== "title");
        });
      }
      return;
    }

    // if we are changing the active status to Terminated, we need to clear all other changes
    if (action === "active_status" && selection === "T") {
      setAvailablePositions([]);
      setSelectedChanges([
        {
          action,
          selection,
          changeKey,
        },
      ]);
      return;
    }
    const newSelectedChanges = [...selectedChanges];
    const index = newSelectedChanges.findIndex(
      (change) => change.action === action
    );
    if (index > -1) {
      newSelectedChanges[index].selection = selection;
      newSelectedChanges[index].changeKey = changeKey;
    } else {
      newSelectedChanges.push({ action, selection, changeKey });
    }
    setSelectedChanges(newSelectedChanges);
  };

  useEffect(() => {
    console.log(selectedChanges);
  }, [selectedChanges]);

  const onSave = () => {
    const constructPayload = () => {

      const actionsArray: any[] = [];

      // we create an actions array to send to the api
      // each action is an object with the employee_id, action, and selection
      selectedEmployees.forEach((employee) => {
          console.log("employee going in body", employee);
          // @ts-ignore
          actionsArray.push({
            employee_id: employee.Momentum_id,
            actions: selectedChanges.map((change) => {
              return (
                {
                  action: change.action,
                  selection: change.selection,
                  old_selection: employee[change.changeKey] || "",
                }
              )}),
          });
      })
      return actionsArray;
    }

    const payload = constructPayload();
    const body = {
      requested_by: azureAccount?.account?.userName || "",
      updates: payload,
    }
    console.log("BODYYY", body);
    mutate(body);
  };

  const onClose = () => {
    setSelectedChanges([]);
    setOpen(false);
  };

  const terminatedSelected = selectedChanges.find(
    (change) => change.action === "active_status" && change.selection === "T"
  )
    ? true
    : false;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Bulk Actions
        </Typography>
        <p>You are about to update {selectedEmployees.length} employee(s)</p>
        <ActionsContainer>
          <FilterSelect
            disabled={terminatedSelected}
            label="Update Market"
            showClear
            options={actionSelections?.markets || []}
            itemKey="value"
            value={
              selectedChanges.find((change) => change.action === "market")
                ?.selection || ""
            }
            onSelect={(e: string) => {
              onActionSelect("market", e, "Office_Code");
            }}
          />
          <Autocomplete
            disabled={terminatedSelected}
            options={actionSelections?.department_groupings || []}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            value={
              actionSelections?.department_groupings?.find((action: any) => {
                return action.value ===
                  selectedChanges.find((change) => change.action === "department")?.selection
                
              })?.label || ""
            }
            onChange={(e: any) => {
              const optionIndex = e.target.dataset.optionIndex;
              // we find the titles for the selected department
              const titles =
                actionSelections?.department_groupings[optionIndex]
                  ?.job_titles || [];
              setAvailablePositions(titles);

              // get the optionIndex from the even target dataset
              // set the selection to the value of department_groupings
              // of the optionIndex
              onActionSelect(
                "department",
                actionSelections?.department_groupings[optionIndex]?.value ||
                  "",
                "Dept_Code"
              );
            }}
            groupBy={(option: any) => option.department_grouping}
            renderInput={(params) => (
              <TextField {...params} label="Update Department" />
            )}
            sx={{
              width: 210,
              height: 34,
              "& .MuiOutlinedInput-root": {
                height: 34,
                padding: "0 4px 7.5px 5px",
                backgroundColor: COLORS.LIGHT_GRAY,
              },
              // "& .MuiInputLabel-root": {
              //   top: "-25%",
              // },
            }}
          />
          <FilterSelect
            disabled={terminatedSelected || availablePositions.length < 0}
            label="Update Job Title"
            showClear
            itemKey="value"
            options={availablePositions || []}
            value={
              selectedChanges.find((change) => change.action === "title")
                ?.selection || ""
            }
            onSelect={(e: string) => {
              console.log("EEE", e);
              onActionSelect("title", e, "Job_Title");
            }}
          />
          <FilterSelect
            label="Update Active Status"
            showClear
            options={
              actionSelections?.active_status.filter(
                (status: any) => status.value !== "-"
              ) || []
            }
            itemKey="value"
            value={
              selectedChanges.find(
                (change) => change.action === "active_status"
              )?.selection || ""
            }
            onSelect={(e: string) => {
              console.log("EEE", e);
              onActionSelect("active_status", e, "Active_status");
            }}
          />
          <span style={{ width: 212 }} />
        </ActionsContainer>
        {isLoading ? (
          <PropagateLoader color={COLORS.PRIMARY} />
        ) : (
          <SaveContainer>
            <Button
              onClick={() => {
                onClose();
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={onSave}>Save</Button>
          </SaveContainer>
        )}
      </Box>
    </Modal>
  );
}

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 85%;
  height: 40%;
  justify-content: space-between;
  align-items: center;
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

const body = {
  employees_to_update: [
    {
      employee_id: "M34214",
      actions: [
        {
          action: "department", 
          selection: "SLSREP"
        },
        {
          action: "title",
          selection: 6
        },
        {
          action: "market",
          selection: "FL-ORLANDO"
        },
        {
          action: "active_status",
          selection: "A"
        }
      ]
    },
  ]
}