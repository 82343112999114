import React from "react";
import styled from "@emotion/styled";
import { useS3, useFileQuery } from "./hooks";
import { ReactComponent as FileIconBlue } from "../../assets/FileIconBlue.svg";
import { BarLoader } from "react-spinners";
import { ReactComponent as CheckMarkWhite } from "../../assets/CheckMarkWhite.svg";
import { ReactComponent as XWhite } from "../../assets/XWhite.svg";

const fileSize = "3.2 MB";

export default function UploadTool({
  file,
  setFile,
  fileError,
  setFileError,
}: {
  file: any;
  setFile: any;
  fileError: any;
  setFileError: any;
}) {
  // upload to s3 and get file url
  const { uploadToS3, fileUrl, fileUploading, uploadError, uploadStatus } = useS3();

  // get file status
  const { existingFile } = useFileQuery();

  const onCancel = () => {

  };

  return (
    <div>
      <div>
        <div>
          {fileUploading ? (
            <div>UploadingFile</div>
          ) 
            : uploadStatus === "uploaded" ? (
            <div>File Uploaded</div>
          )
            : file ? (
            <div>File Ready to Upload</div>
          ) : fileError ? (
            <div>File Error</div>
          ) : null}
        </div>
        {file &&
        <FileRow>
          <FileIconBlue />
          <div>
            <p>{file.name}</p>
            <BarLoader
              color={"#123abc"}
              loading={fileUploading}
            />
            <p>{fileSize}</p>
            {
              existingFile ? <div>Only one upload allowed per 24 hours</div>
              :
              !fileUploading && !fileError && file && uploadStatus !== "uploaded" && <button onClick={() => uploadToS3(file)}>Upload</button>
            }
          </div>
          {
            fileUrl ? 
            <GreenCircle>
              <CheckMarkWhite />
            </GreenCircle>
            : 
            <CancelButton onClick={onCancel}>
              <XWhite />
            </CancelButton>
          }
        </FileRow>}
      </div>
    </div>
  );
}

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;

const CancelButton = styled.button`
  background-color: #d8d8d8;
  border: none;
  border-radius: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GreenCircle = styled.div`
  background-color: #a4edc3;
  border: none;
  border-radius: 100px;
  padding: 5px 6px;
`;
