import React from "react";
import { Wrapper, Container } from "./Styled";

export default function NoAccess() {
  return (
    <Wrapper>
        <Container>
            <h1>Access Denied</h1>
            <p>You do not have access to this page.</p>
        </Container>
    </Wrapper>
  )
}
