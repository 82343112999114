import { useState } from "react";
import styled from "@emotion/styled";
import DragDropFile from "./DragDropFile";
import FileViewer from "./FileViewer";
import FileError from "./FileError";
import ProcessingStatus from "./ProcessingStatus";
import UploadTool from "./UploadTool";
import FilesTable from "./FilesTable";
import { useFileQuery, useS3 } from "./hooks";
import { COLORS } from "../../Theme";
// import "./style.css";

export default function COADFileDrop() {
  // store file
  const [file, setFile] = useState<any>(null);
  // store file type errors
  const [fileError, setFileError] = useState<string | null>(null);

  return (
    <Container>
      <div id="left-container"> 
          <p>Upload COAD File</p>
          <DragDropFile setFile={setFile} setFileError={setFileError} />
          <div id="fileContainer">
            <UploadTool 
              file={file}
              setFile={setFile}
              fileError={fileError}
              setFileError={setFileError}
            />
            <ProcessingStatus />
          </div>
      </div>
      <FilesTable />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 80%;
  height: 80%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #585858;
  
  #left-container {
    width: 30%;
  }

  #fileContainer {

  }
  p {
    color: ${COLORS.PRIMARY};
  }
`;