import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";

const QueueContainer = styled.div`
  position: relative;
  width: 100%;
  // height: 100%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  //   align-items: center;
  padding: 20px;
  color: #585858;
  margin-top: 20px;

  #left-container {
    width: 30%;
  }

  #fileContainer {
  }
  p {
    // color: #d5d5d5;
  }
  #title {
    color: ${COLORS.PRIMARY};
    font-family: "Gotham Bold";
    margin-right: 20px;
  }
  #header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  #header-tools-container {
    display: flex;
    width: 30%;
    justify-content: space-between;
  }
  #table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #table-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
  #table-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }

  #edit-container {
    display: flex;
    width: 150px;
    justify-content: space-between;
    align-items: center;
    color: ${COLORS.GRAY};
    cursor: pointer;
  }

  .queue-inner-container {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default QueueContainer;