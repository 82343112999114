import React, { useContext } from "react";
import AuthContext from "./AuthContext";
import { Route, useLocation, Navigate, } from "react-router-dom";
import { NoAccess } from "../GlobalComponents";

const PrivateRoute = ({
  children,
  requiredPermissions,
}: {
  children: any;
  requiredPermissions?: string | undefined;
}): React.ReactElement => {
  const { isAuthenticated, setOldPath, isDevAccount, permissions } =
    useContext(AuthContext);
  const history = useLocation();
  console.log("required permissions", requiredPermissions);
  console.log("permissions", permissions);

  if (isAuthenticated) {
    console.log("private route returning children");
    if (requiredPermissions) {
      const hasAccess = permissions?.includes(requiredPermissions);
      console.log("has access", hasAccess);
      if (hasAccess) {
        return children;
      } else {
        return <NoAccess />;
      }
    } else {
      return children;
    }
  } else {
    console.log("private route detecting unauthenticated", isAuthenticated);
    setOldPath(history.pathname);
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
