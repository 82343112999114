import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import LoginButton from "./LoginButton";
import AuthContext from "../AuthContext";
import { Navigate } from "react-router-dom";
import { BackgroundImage } from "../../GlobalComponents";
import { ReactComponent as MSLogo } from "../../assets/MSLogo.svg";
import { ReactComponent as WaldoHead } from "../../assets/waldo-parts/WaldoHead.svg";
import { ReactComponent as WaldoHand } from "../../assets/waldo-parts/WaldoHand.svg";

const StyledLogin = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  font-family: ${(props: any) => props.theme.font};
  .loginCard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 125px 50px;
    border-radius: 15px;
    background-color: #ffffff;
    margin-bottom: 20px;
  }
  h3 {
    text-align: center;
  }
  h6 {
    margin: 15px 40px 55px 40px;
    text-align: center;
  }

  h5 {
    text-align: center;
  }
  .noMatch {
    font-family: ${(props: any) => props.theme.font};
    margin: 25px 0;
    font-size: 20px;
    text-align: center;
  }

  .form-width {
    width: 12.6;
  }

  .background-logo-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: -100;

    background: -webkit-linear-gradient(${(props) => props.color}, #fff);
    background: -moz-linear-gradient(${(props) => props.color}, #fff);
    background: -ms-linear-gradient(${(props) => props.color}, #fff);
    background: -o-linear-gradient(${(props) => props.color}, #fff);
    background: linear-gradient(${(props) => props.color}, #fff);
  }
`;

const Login = ({ login, authCheck }: { login: any, authCheck: any }) => {
  const { setAuthenticated, oldPath } = useContext(AuthContext);

  //we need to do this weird thing because logout is acting like iiffe

  const loginClick = (e: any) => {
    e.preventDefault();
    login();
  };
  return authCheck ? (
    <Navigate to={{ pathname: `${oldPath}` }} />
  ) : (
    <StyledLogin>
      <div className="loginCard">
        <HeadContainer>
          <WaldoHead width={250} />
        </HeadContainer>
        <HandContainerLeft>
          <WaldoHand width={60} />
        </HandContainerLeft>
        <HandContainerRight>
          <WaldoHand transform="scale(-1,1)" width={60} />
        </HandContainerRight>
        <MSLogoContainer>
          <MSLogo width={300}/>
        </MSLogoContainer>
        <LoginButton onClick={loginClick} />
      </div>
      <div className="background-logo-wrapper">
        <BackgroundImage />
      </div>
    </StyledLogin>
  );
};

export default Login;

const HeadContainer = styled.div`
  position: absolute;
  top: -55%;
  left: 21%;
`;

const HandContainerLeft = styled.div`
  position: absolute;
  top: 30px;
  left: -7%;
  width: 100%;
`;

const HandContainerRight = styled.div`
  position: absolute;
  top: 30px;
  left: 93%;
  width: 100%;
`;

const MSLogoContainer = styled.div`
  margin-bottom: 20px;
`;