import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";
import AuthContext from "../../../../Auth/AuthContext";

// api
import {
  fetchEmployees,
  fetchOptions,
  postUpdateEmployeesRequest,
} from "../../../api";

// styled components
import { Container } from "../../Components/Styled";

// assets
import { ReactComponent as BackArrow } from "../../../../assets/ArrowGray.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/PersonIcon.svg";

// components
import { FilterSelect, Input } from "../../Components";
import { Button, EmployeeInfo } from "../../../../GlobalComponents";
import SuccessModal from "../../Components/SuccessModal";

// mui
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function RosterSingleEmployee() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { azureAccount } = useContext(AuthContext);

  const [selectedChanges, setSelectedChanges] = useState<any[]>([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [availablePositions, setAvailablePositions] = useState<any[]>([]);

  const {
    data: employeesData,
    error: employeesError,
    isLoading: employeesLoading,
    refetch: employeesRefetch,
  } = useQuery({
    queryKey: ["employees"],
    queryFn: () => fetchEmployees("momentum_id", id),
    enabled: true,
    retry: false,
  });

  const {
    data: optionsData,
    error: optionsError,
    isLoading: optionsLoading,
    refetch: optionsRefetch,
  } = useQuery({
    queryKey: ["options"],
    queryFn: () => fetchOptions(),
    enabled: true,
    retry: false,
  });

  const { mutate, isLoading: updateLoading } = useMutation(postUpdateEmployeesRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(["employees"]);
      setConfirmationText("Your Request has been submitted!");
      setConfirmationModalOpen(true);
    },
    onError: () => {
      alert("There was an error submitting your request. Please try again later.");
    }
  });

  const goBack = () => {
    navigate("/roster/employees");
  };

  const employee = employeesData?.values?.[0];

  const onActionSelect = (action: string, selection: string, old_selection?: string) => {

    if(!selection) {
      // if selection is "" we remove the action from the selected changes
      const newSelectedChanges = [...selectedChanges];
      const index = newSelectedChanges.findIndex(
        (change) => change.action === action
      );
      if (index > -1) {
        newSelectedChanges.splice(index, 1);
        setSelectedChanges(newSelectedChanges);
      }

      if(action === "department") {
        // remove title from selected options
        setSelectedChanges((prev) => {
          return prev.filter((change) => change.action !== "title");
        });
      }
      return;
    }
   
    // if we are changing the active status to Terminated, we need to clear all other changes
    if(action === "active_status" && selection === "T") {
      setSelectedChanges([{
        action,
        selection,
        old_selection,
      }]);
      return
    }
    const newSelectedChanges = [...selectedChanges];
    const index = newSelectedChanges.findIndex(
      (change) => change.action === action
    );
    if (index > -1) {
      newSelectedChanges[index].selection = selection;
      newSelectedChanges[index].old_selection = old_selection;
    } else {
      newSelectedChanges.push({ action, selection, old_selection });
    }
    setSelectedChanges(newSelectedChanges);
  };

  const onSave = () => {

    const validateEmail = (email: string) => {
      if(!email.includes("@momentumsolar.com")) return false;
      const re = /\S+@\S+\.\S+/
      return re.test(email);
    }

    if(selectedChanges.find((change) => change.action === "email")) {
      if(!validateEmail(selectedChanges.find((change) => change.action === "email")?.selection)) {
        setEmailError("Please enter a valid Momentum Solar email address");
        return;
      } else {
        setEmailError("");
      }
    }

    const constructPayload = () => {
      const actionsArray: any[] = [];

      // we create an actions array to send to the api
      // each action is an object with the employee_id, action, and selection
      selectedChanges.forEach((change) => {
        // @ts-ignore
        actionsArray.push({
          action: change.action,
          selection: change.selection,
          old_selection: change.old_selection,
        });
      });

      return actionsArray;
    };

    const payload = constructPayload();
    const body = {
      requested_by: azureAccount?.account?.userName || "",
      updates: [
        {
          employee_id: employee?.Momentum_id,
          actions: payload,
        }
      ]
    }

    return mutate(body);
  };

  useEffect(() => {
    console.log("selected changes", selectedChanges);
  },[selectedChanges]);

  const defaultJobTitles =
    optionsData?.department_groupings.find(
      (dept: any, idx: number) => dept.value === employee?.Dept_Code
    )?.job_titles || [];

  const terminatedSelected = selectedChanges.find(
    (change) => change.action === "active_status" && change.selection === "T"
  )
    ? true
    : false;

  const departmentIsSelected = !!selectedChanges.find((change) => change.action === "department")
            
  return (
    <Wrapper>
      <EmployeeInfo
        headerText="Employee Details"
        employee={employee}
        error={!!employeesError || !!optionsError}
        errorMessage="There was an error loading the data. Please try again later."
        active_status={
          optionsData?.active_status?.find(
            (opt) => opt.value === employee?.Active_status
          )?.label || "-"
        }
        showBackButton={true}
        footer={
          <div id="footer">
            <Button
              onClick={() => {
                setEmailError("");
                setSelectedChanges([]);
              }}
            >
              Clear All
            </Button>
            <Button disabled={selectedChanges.length === 0} onClick={onSave}>
              Save
            </Button>
          </div>
        }
        isLoading={employeesLoading || optionsLoading || updateLoading}
      >
        <div id="actions-container">
          <Autocomplete
            disabled={terminatedSelected}
            options={
              optionsData?.department_groupings.filter((dept) => {
                return dept.value !== employee?.Dept_Code;
              }) || []
            }
            value={
              optionsData?.department_groupings?.find((action: any) => {
                return (
                  action.value ===
                  selectedChanges.find(
                    (change) => change.action === "department"
                  )?.selection
                );
              })?.label || ""
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            onChange={(e: any) => {
              console.log("autocomplete change", e.target.textContent);
              // clear selected title
              setSelectedChanges((prev) => {
                return prev.filter((change) => change.action !== "title");
              });

              const optionIndex = e.target.dataset.optionIndex;

              // we have to remove the current department from the options
              const cleanedDepts =
              optionsData?.department_groupings.filter((dept) => {
                return dept.value !== employee?.Dept_Code;
              }) ||
              optionsData?.department_groupings ||
              [];
              // we find the titles for the selected department
              const titles = cleanedDepts[optionIndex]?.job_titles || [];
              setAvailablePositions(titles);

              // get the optionIndex from the even target dataset
              // set the selection to the value of department_groupings
              // of the optionIndex
              onActionSelect(
                "department",
                cleanedDepts[optionIndex]?.value || "",
                employee?.Dept_Code || ""
              );
            }}
            groupBy={(option: any) => option.department_grouping}
            renderInput={(params) => (
              <TextField {...params} label="Update Department" />
            )}
            sx={{
              width: 300,
              height: 34,
              "& .MuiOutlinedInput-root": {
                height: 34,
                padding: "0 4px 7.5px 5px",
                backgroundColor: COLORS.LIGHT_GRAY,
              },
            }}
          />
          <FilterSelect
            disabled={terminatedSelected}
            label="Update Job Title"
            showClear
            itemKey="value"
            options={
              availablePositions.length
                ? availablePositions
                : !departmentIsSelected
                ? defaultJobTitles
                : []
            }
            value={
              selectedChanges.find((change) => change.action === "title")
                ?.selection || ""
            }
            onSelect={(e: string) => {
              console.log("EEEE", e);
              onActionSelect("title", e, employee?.Job_Title || "");
            }}
            width="300px"
          />
          <FilterSelect
            disabled={terminatedSelected}
            label="Update Market"
            showClear
            options={
              optionsData?.markets.filter(
                (market) => market.value !== employee?.Office_Code
              ) || []
            }
            itemKey="value"
            value={
              selectedChanges.find((change) => change.action === "market")
                ?.selection || ""
            }
            onSelect={(e: string) => {
              console.log("EEEE", e);
              onActionSelect("market", e, employee?.Office_Code || "");
            }}
            width="300px"
          />
          <FilterSelect
            label="Update Active Status"
            showClear
            options={
              optionsData?.active_status.filter(
                (status) =>
                  status.value !== employee?.Active_status &&
                  status.value !== "-"
              ) || []
            }
            itemKey="value"
            value={
              selectedChanges.find(
                (change) => change.action === "active_status"
              )?.selection || ""
            }
            onSelect={(e: string) => {
              onActionSelect("active_status", e, employee?.Active_status || "");
            }}
            width="300px"
          />
          <Input
            disabled={terminatedSelected}
            value={
              selectedChanges.find((change) => change.action === "first_name")
                ?.selection || ""
            }
            placeholder="Update First Name"
            onChange={(text) => {
              onActionSelect("first_name", text, employee?.First_Name || "");
            }}
            style={{ width: 300 }}
          />
          <Input
            disabled={terminatedSelected}
            value={
              selectedChanges.find((change) => change.action === "last_name")
                ?.selection || ""
            }
            placeholder="Update Last Name"
            onChange={(text) => {
              onActionSelect("last_name", text, employee?.Last_Name || "");
            }}
            style={{ width: 300 }}
          />
          <Input
            disabled={terminatedSelected}
            value={
              selectedChanges.find((change) => change.action === "email")
                ?.selection || ""
            }
            placeholder="Update Email"
            onChange={(text) => {
              onActionSelect("email", text, employee?.Email || "");
            }}
            errorMessage={emailError}
            style={{ width: 300 }}
          />
        </div>
      </EmployeeInfo>
      <SuccessModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        modalTitleText={confirmationText}
        onClose={goBack}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 40px;

  #actions-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 20px;
    width: 100%;
    height: 175px;
    // background-color: red;
    margin-bottom: 20px;
  }
`;