import { ReactComponent as MicrosoftLogin } from "../../assets/MicrosoftLogin.svg";
import styled from "@emotion/styled";

//styles
import { LoginB } from "./styles";

const LoginButton = ({ onClick }: { onClick: (e: any) => void }) => {
  return (
    <Container>
      <MicrosoftLogin width={350} onClick={onClick} />
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
`;

export default LoginButton;
