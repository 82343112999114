import React, { useContext } from "react";
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from "react-aad-msal";
import { authProvider } from "./authConfig";
import AuthContext from "./AuthContext";

const devAccounts = [
  "christopher.foy@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "derrick.huntley@momentumsolar.com",
  "dirwin@momentumsolar.com",
  "brett.sodie@momentumsolar.com",
  "gmarciano@momentumsolar.com",
  "MChrist@MomentumSolar.com",
  "megan.souther@momentumsolar.com",
];

const AzureAuth = ({ children, ...props }: { children: React.ReactNode }) => {
  console.log("auth is running")
  const {
    setAuthProviderLocal,
    setAuthenticated,
    isAuthenticated,
    setAzureAccount,
    setLogout,
    setIsDevAccount,
    setOldPath,
    setPermissions,
  } = useContext(AuthContext);

  const authSet = (bool: boolean) => {
    setAuthenticated(bool);
  };

  const checkIfDevAccount = (email: string | null | undefined) => {
    if (
      email &&
      devAccounts.some((acc) => acc.toLowerCase() === email.toLowerCase())
    ) {
      setIsDevAccount(true);
      setPermissions(["roster"]);
    } else {
      setIsDevAccount(false);
      setPermissions([]);
    } 
  }

  return (
    // @ts-ignore
    <AzureAD provider={authProvider}>
      {({ login, logout, authenticationState, error, accountInfo }: IAzureADFunctionProps) => {
        console.log("auth is doing something", authenticationState)
        const wrappedLogin = () => {
          return login;
        };
        setAuthProviderLocal(wrappedLogin);
        //run my auth

        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            if (isAuthenticated !== true) {

              const wrappedLogout = () => {
                return logout;
              };
              console.log("account info", accountInfo)

              setAzureAccount(accountInfo);
              checkIfDevAccount(accountInfo?.account.userName)
              setLogout(wrappedLogout);
              authSet(true);
            } else {
              setAzureAccount(accountInfo);
              authSet(true);
            }
            return children;
          case AuthenticationState.Unauthenticated:
            console.log("setting auth to false")
            authSet(false);
            return children;
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>;
          default:
            return <p>Something happened</p>;
        }
      }}
    </AzureAD>
  );
};

export default AzureAuth;
