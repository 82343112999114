import { useState } from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";
import { ReactComponent as EditIcon } from "../../../../assets/EditIcon.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/PersonIcon.svg";
import RemoveFromTeamModal from "./RemoveFromTeamModal";
import TransferTeamModal from "./TransferTeamModal";

// components
import { Button } from "../../../../GlobalComponents";
import { QueueContainer } from "../../Components/Styled";
import { QueueEmployeeCard } from "../../Components";

export default function TeamQueue({
  queueList = [],
  team = {},
  onEdit,
  teamOptions = [],
}: {
  queueList: any[];
  team: any;
  onEdit: any;
  teamOptions: any[];
}) {

  const [removeFromTeamModalOpen, setRemoveFromTeamModalOpen] = useState<boolean>(false);
  const [transferTeamModalOpen, setTransferTeamModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [confirmationModalText, setConfirmationModalText] = useState<string>("");

  const onEditClick = () => {
    if (queueList.length > 0) {
      onEdit();
    }
  };

  // console.log("team", team)

  return (
    <QueueContainer>
      <div id="header">
        <p id="title">Queue</p>
        <div>
          <p>{team?.team_name}</p>
          <p>{queueList.length} members on this team</p>
        </div>
        <div>
          <p>Edit Team</p>
          <EditIcon onClick={onEditClick} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className="queue-inner-container">
        {queueList.map((employee: any) => (
          <EmployeeCard key={employee.id}>
            <div
              className={`icon-container ${
                employee.status === "active" ? "active" : "inactive"
              }`}
            >
              <PersonIcon width={65} height={65} />
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>{employee.Name}</p>
              <p>{employee.Office_Code}</p>
            </div>
            <div>
              <Button
                onClick={() => {
                  setSelectedEmployee(employee);
                  setRemoveFromTeamModalOpen(true);
                }}
              >
                Remove from Team
              </Button>
              <Button
                onClick={() => {
                  setSelectedEmployee(employee);
                  setTransferTeamModalOpen(true);
                }}
              >
                Transfer Team
              </Button>
            </div>
          </EmployeeCard>
        ))}
      </div>
      <RemoveFromTeamModal
        open={removeFromTeamModalOpen}
        setOpen={setRemoveFromTeamModalOpen}
        employee={selectedEmployee}
        setEmployee={setSelectedEmployee}
        team={team}
      />
      <TransferTeamModal
        open={transferTeamModalOpen}
        setOpen={setTransferTeamModalOpen}
        employee={selectedEmployee}
        setEmployee={setSelectedEmployee}
        teamOptions={teamOptions}
      />
    </QueueContainer>
  );
}

const EmployeeCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid ${COLORS.PRIMARY};
  width: 190px;
  height: 225px;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  padding: 10px 0;
  font-size: 14px;

  p {
    color: #000;
    text-align: center;
    line-height: 10px;
  }

  .icon-container {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  .active {
    border: 2px solid #d1f7d5;
  }

  .inactive {
    border: 2px solid #fcbdbd;
  }
`;