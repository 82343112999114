import { useState } from "react";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIA2VYLNHYVRZ4L2MP6",
  secretAccessKey: "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",
  region: "us-east-1",
  signatureVersion: "v4",
});

export default function useS3() {
  const s3 = new AWS.S3();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<"processing" | "uploaded" | "error" | null>(null);

  const uploadToS3 = async (file: any) => {
    console.log("file", file)
    setIsLoading(true);
    if (!file) {
      setIsLoading(false);
      return;
    }
    const params = {
      Bucket: "waldocoadv2",
      Key: `${Date.now()}.${file.name}`,
      Body: file,
    };
    try {
      const { Location } = await s3.upload(params).promise();
      setFileUrl(Location);
      setStatus("uploaded");
      setIsLoading(false);
      console.log("uploading to s3", Location);
    }
    catch (err) {
      setError("Error uploading file");
      setIsLoading(false);
      console.log("error uploading to s3", err);
    }
  };

  return {
    fileUrl,
    uploadToS3,
    isLoading,
    error,
    fileUploading: isLoading,
    uploadError: error,
    uploadStatus: status,
  }
}
