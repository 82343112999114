import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { COLORS } from "../../../Theme";
import { Button } from "../../../GlobalComponents";
import { ReactComponent as CheckMarkWhite } from "../../../assets/CheckMarkWhite.svg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 300,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  color: COLORS.PRIMARY,
  padding: "30px 0px",
};

export default function SuccessModal({
  open,
  setOpen,
  modalTitleText,
  onClose,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  modalTitleText?: string;
  onClose?: () => void;
}) {
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose();
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{width: 300, textAlign: "center"}} id="modal-modal-title" variant="h6" component="h2">
          {modalTitleText || "You have successfully completed the action!"}
        </Typography>
        <ActionsContainer>
          <div className="icon-container">
            <CheckMarkWhite width={85} height={85} />
          </div>
        </ActionsContainer>
        <Button
          onClick={() => {
            onClose && onClose();
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

const ActionsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 80%;
  height: 50%;
  justify-content: center;
  align-items: center;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: ${COLORS.LIGHT_GREEN};
    align-self: center;
  }
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;
