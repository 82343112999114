import { useState, useContext } from "react";
import { Typography, Modal, Box } from "@mui/material";
import AuthContext from "../../Auth/AuthContext";
import { ReactComponent as PersonIcon } from "../../assets/PersonIcon.svg";
import { COLORS } from "../../Theme";
import MSLogo from "../../assets/MSLogo2.png";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import BuildIcon from "@mui/icons-material/Build";

import styled from "styled-components";

// import MSLogo from "../../assets/msLogo.png";
import { ReactComponent as MSLogo2 } from "../../assets/MSLogo2.svg";

import { version, versionNotes } from "../../config";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  minHeight: 300,
  maxHeight: 300,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

function Header() {
  const { logout, azureAccount } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  // const user = useMsal().instance.getActiveAccount();

  console.log("AZURE ACCOUNT", azureAccount);
  console.log("version notes", versionNotes);
  const logo = "../../assets/MsLogo2.png"
  

  return (
    <StyledHeader>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "2em",
          marginTop: "1em",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", paddingBottom: 10 }}
        >
          <img
            src={MSLogo}
            alt="MSLogo"
            style={{ width: 225, height: "auto", marginRight: "2em" }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 60,
              cursor: "pointer",
              paddingTop: 2,
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#000" }}
            >
              v {version}
            </Typography>
            <BuildIcon
              sx={{
                color: "#000",
                width: 15,
                height: "auto",
                marginLeft: "10px",
                paddingBottom: 0.5,
              }}
            />
          </div>
        </div>
      </div>

      {azureAccount && (
        <div id="account-container">
          {/* <TestEmailButton /> */}
          <PersonIcon />
          <p>{azureAccount.account.name}</p>
          <span className="divider" />
          <p className="btn" onClick={logout}>
            Logout
          </p>
          {/* <StyledButton onClick={logout} text={"Logout"} /> */}
        </div>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Version {version}
          </Typography>
          <div style={{ maxHeight: 280, overflowY: "scroll" }}>
            {versionNotes[version]?.map((note: any) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: 40,
                    }}
                  >
                    <StarOutlineIcon color="primary" />
                    <Typography
                      id="modal-modal-description"
                      color="primary"
                      sx={{
                        mt: 2,
                        paddingBottom: 1.5,
                        marginLeft: 1,
                        fontFamily: "Gotham Bold",
                      }}
                    >
                      {note.title}
                    </Typography>
                  </div>
                  <div style={{ paddingLeft: 30 }}>
                    {note.notes.map((subNote: string) => {
                      return (
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          {`- ${subNote}`}
                        </Typography>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
    </StyledHeader>
  );
}

export default Header;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4em;
  color: #fff;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #D8D8D8;

  img {
    height: 3em;
  }

  p {
    color: ${COLORS.CHARCOAL};
    font-size: 0.9em;
    margin: 0 1em;
    font-family: "Gotham Bold";
  }

  .btn {
    cursor: pointer;
  }

  #account-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2em;
  }

  .divider {
    width: 2px;
    height: 1.2em;
    background-color: ${COLORS.PRIMARY};
    // margin: 0 1em;
`;
