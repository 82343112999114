// components
import { Button } from "../../../../GlobalComponents";
import { QueueContainer } from "../../Components/Styled";
import { QueueEmployeeCard } from "../../Components";

// assets
import { ReactComponent as EditIcon } from "../../../../assets/EditIcon.svg";

export default function Queue({
  queueList = [],
  onEdit,
  onRemove,
  onRemoveAll,
}: {
  queueList: any[];
  onEdit: any;
  onRemove: (empId: string) => void;
  onRemoveAll: () => void;
}) {
  const onEditClick = () => {
    if (queueList.length > 0) {
      onEdit();
    }
  };

  return (
    <QueueContainer>
      <div id="header">
        <div style={{display: "flex", width: "20%",}}>
          <p id="title">Queue</p>
          <Button onClick={onRemoveAll}>Clear All</Button>
        </div>
        <p>{queueList.length} Employee(s) Selected</p>
        <div id="edit-container" onClick={onEditClick}>
          <p>Bulk Actions</p>
          <EditIcon style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className="queue-inner-container">
        {queueList.map((employee: any) => (
          <QueueEmployeeCard
            employee={employee}
            onRemove={onRemove}
          />
        ))}
      </div>
    </QueueContainer>
  );
}