import styled from "@emotion/styled";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { COLORS } from "../../../Theme";
import { Button } from "../../../GlobalComponents";
import { TableInnerContainer } from "../Components/Styled";


export default function TeamsTable({
    rows,
    onSelect,
    onEdit,
}: {
    rows: any[];
    onSelect: (arg: number) => void;
    onEdit: (arg: number) => void;
}) {

  const columns: GridColDef[] = [
      { field: "team_name", headerName: "Team", width: 200 },
      { field: "market", headerName: "Market", width: 250 },
      { field: "team_lead", headerName: "Team Lead", width: 250 },
      {
        field: "view",
        headerName: "",
        width: 160,
          renderCell: (params: GridRenderCellParams) => (
              <Button
                  onClick={() => onSelect(params.row.id)}
              >
                <p style={{fontSize: "13px", color: "#fff"}}>
                  View Team
                </p>
              </Button>
          ),
      },
      {
        field: "edit",
        headerName: "",
        width: 160,
          renderCell: (params: GridRenderCellParams) => (
              <Button
                  onClick={() => {
                    console.log("ROW PARAMS", params.row.id)
                    onEdit(params.row.id)
                  }}
              >
                <p style={{fontSize: "13px", color: "#fff"}}>

                  Edit Team
                </p>
              </Button>
          ),
      }
  ];

  return (
    <TableInnerContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        // hideFooter
        // loading={loading}
      />
    </TableInnerContainer>
  );
}

const StyledLink = styled.a`
  background-color: ${COLORS.PRIMARY};
  color: #fff;
  padding: 3px 35px;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
