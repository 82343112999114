import React from "react";
import styled from "@emotion/styled";
import { useFileStatusQuery } from "./hooks";
import { BarLoader } from "react-spinners";
import { ReactComponent as FileIconBlue } from "../../assets/FileIconBlue.svg";

const fileName = "Filename.xlsx"
const fileSize = "3.2 MB"

export default function ProcessingStatus() {
  const {
    status: fileProcessingStatus,
    isLoading: fileProcessing,
    error,
  } = useFileStatusQuery();

  if(!fileProcessing) return null;

  return (
    <div>
        <div>Processing File</div>
        <FileRow>
            <FileIconBlue/>
            <div>
                <p>{fileName}</p>
                <BarLoader
                    color={"#123abc"}
                    loading={fileProcessingStatus === "processing"}
                />
                <p>{fileSize}</p>
            </div>
        </FileRow>
    </div>
  );
}

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;