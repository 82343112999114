import { useContext } from 'react';
import PrivateRoute from './Auth/PrivateRoute';
import Login from './Auth/Login/Login';
import AuthContext from './Auth/AuthContext';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import {
  Dashboard,
  COADFileDrop,
  RosterList,
  RosterSingleEmployee,
  RosterTeams,
  ReviewChangeOrder,
} from "./Views";
import './App.css';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Background } from './GlobalComponents';
import { featureFlags } from "./config";
import { createTheme, createStyles, ThemeProvider } from '@mui/material';
import { COLORS } from './Theme';



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      networkMode: "online",
      cacheTime: 1000 * 60 * 60 * 24,
    },
    mutations: {
      networkMode: "online",
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 34,
          padding: "0 4px 7.5px 5px",
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-7px",
          fontSize: "14px"
        }
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        }
      }
    }
  }
});

function App() {
  const { authProviderLocal, isAuthenticated } = useContext(AuthContext);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Background />}>
        <Route
          path="/login"
          element={
            <Login login={authProviderLocal} authCheck={isAuthenticated} />
          }
        />
        <Route
          index
          element={
            <PrivateRoute requiredPermissions="roster">
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/coadfiledrop"
          element={
            <PrivateRoute>
              <COADFileDrop />
            </PrivateRoute>
          }
        />
        <Route
          path="/roster/employees"
          element={
            <PrivateRoute requiredPermissions="roster">
              <RosterList />
            </PrivateRoute>
          }
        >
          <Route
            path="/roster/employees/:id"
            element={
              <PrivateRoute requiredPermissions="roster">
                <RosterSingleEmployee />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/roster/teams"
          element={
            <PrivateRoute requiredPermissions="teams">
              <RosterTeams />
            </PrivateRoute>
          }
        />
        <Route
          path="/review-change-order/:id"
          element={
            <PrivateRoute>
              <ReviewChangeOrder />
            </PrivateRoute>
          }
        />
      </Route>
    )
  );
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
      <RouterProvider router={router}/>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;