import React from "react";
import { useQuery } from "@tanstack/react-query";
import UserInfo from "./UserInfo";
import Tools from "./Tools";
import { WavingWaldo } from "../../GlobalComponents";
import { Wrapper, Container } from "../../GlobalComponents/Styled";
import styled from "@emotion/styled";

export default function Dashboard() {
  return (
    <Wrapper>
      <Container>
        <div>
          <h1>Dashboard - Under Construction!</h1>
        </div>
        <WaldoContainer>
          <WavingWaldo />
        </WaldoContainer>
        {/* <UserInfo />
        <Tools /> */}
      </Container>
    </Wrapper>
  );
}

const WaldoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: auto;
  `;
