import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./style.css";
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";
import CloseIcon from "@mui/icons-material/Close";

export default function FilterSelect({
  label = "",
  value,
  options,
  onSelect,
  bgColor,
  itemKey,
  showClear = false,
  width="210px",
  height="35px",
  style,
  disabled=false,
  boxStyle={}
}: {
  label: string;
  value: string;
  options: { key: string; label: string }[];
  onSelect: (arg: string) => void;
  bgColor?: string;
  itemKey?: string;
  showClear?: boolean;
  width?: string;
  height?: string;
  style?: any;
  disabled?: boolean;
  boxStyle?: any;
}) {
  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120, position: "relative", ...boxStyle }}>
      <FormControl sx={{ position: "relative" }}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          sx={{
            padding: "5px",
            backgroundColor: bgColor || COLORS.LIGHT_GRAY,
            width: width,
            height: height,
            fontFamily: "Lato, sans-serif",
            ...style,
          }}
        >
          {options.map((option: any) => {
            return (
              <MenuItem
                value={itemKey ? option[itemKey] : option.key}
                key={itemKey ? option[itemKey] : option.key}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      {showClear && value && (
        <CloseIcon
          onClick={() => onSelect("")}
          sx={{
            position: "absolute",
            right: "35px",
            top: "7px",
            cursor: "pointer",
            color: COLORS.GRAY,
          }}
        />
      )}
      </FormControl>
    </Box>
  );
}

