import React from "react";
import { TextField } from "@mui/material";
import "./style.css"

export default function Input({
  onChange,
  placeholder,
  errorMessage,
  value,
  style={},
  disabled=false
}: {
  onChange: (arg: string) => void;
  placeholder?: string;
  errorMessage?: string;
  value?: string;
  style?: any;
  disabled?: boolean;
}) {

//   .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
//     height: 33px !important;
// }
  return (
    <TextField
      value={value}
      disabled={disabled}
      error={!!errorMessage}
      helperText={errorMessage}
      sx={{
        minWidth: 210,
        ...style,
        "& .MuiInputBase-root": {
          height: 33,
          fontFamily: "Lato, sans-serif",
          display: "flex",
          alignItems: "center",
        },
        "& MuiInputLabel-root": {
          top: "-7px",
        },
      }}
      id="outlined-basic"
      label={placeholder}
      variant="outlined"
      onChange={(e: any) => onChange(e.target.value)}
    />
  );
}
