import React, { useContext } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import AuthContext from "../Auth/AuthContext";
import { Outlet } from "react-router-dom";

import BackgroundImage from "./BackgroundImage";

import styled from "@emotion/styled";

function Background({
  containerStyle,
  color = "#cccccc",
}: {
  containerStyle?: React.CSSProperties;
  color?: string;
}) {
  const { azureAccount, permissions } = useContext(AuthContext);

  if (!azureAccount) return <div>{<Outlet/>}</div>;

  return (
    <StyledBackground color={color}>
      <div className="page-wrapper">
        <Header />
        <div className="content-wrapper">
          {permissions && permissions?.length > 0 && <SideBar />}
          <div className="children-wrapper" style={containerStyle}>
            {<Outlet/>}
          </div>
        </div>
      </div>
      <div className="background-logo-wrapper">
        <BackgroundImage />
      </div>
    </StyledBackground>
  );
}

export default Background;

const StyledBackground = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .background-logo-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: -100;

    background: -webkit-linear-gradient(${(props) => props.color}, #fff);
    background: -moz-linear-gradient(${(props) => props.color}, #fff);
    background: -ms-linear-gradient(${(props) => props.color}, #fff);
    background: -o-linear-gradient(${(props) => props.color}, #fff);
    background: linear-gradient(${(props) => props.color}, #fff);
  }

  .page-wrapper {
    width: 90%;
    height: 90%;
    // overflow-y: auto;
    // top: 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    // border: 5px solid red;
    border-radius: 20px;
  }

  .content-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
  }
  
  .children-wrapper {
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    width: 100%;
    height: 100%;
  }

  .ms-logo {
    height: 3em;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em;
  }
`;
