import React from "react";
import { ReactComponent as WaldoBg } from "../assets/WaldoBg.svg";
import styled from "@emotion/styled";
import { COLORS } from "../Theme";

export default function BackgroundImage() {
  return (
    <Container>
        <Overlay/>
        <WaldoBg/>
    </Container>
  );
}

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.BACKGROUND};
    opacity: 0.95;
`

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`