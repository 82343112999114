import { Container } from "../../GlobalComponents/Styled";

export default function Success() {
  return (
    <Container>
        <h1>Success</h1>
        <p>Your change order has been submitted.</p>
    </Container>
  )
}
