import styled from "@emotion/styled";

const TableInnerContainer = styled.div`
  height: 55vh;
  width: "100%";
  .css-pzwc4o-MuiDataGrid-root {
    border: none !important;
    border-width: 0 !important;
  }

  .pending-update {
    color: #ffa500;
  }
`;

export default TableInnerContainer;
