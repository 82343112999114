const COLORS = {
  PRIMARY: "#33CCFF",
  SECONDARY: "#336699",
  BACKGROUND: "#F5F9FC",
  CHARCOAL: "#585858",
  LIGHT_GREEN: "#A4EDC3",
  GRAY: "#d5d5d5",
  LIGHT_GRAY: "#f5f5f5",
};

export {
    COLORS,
}