import { useState, useEffect, useContext } from "react";
import { useDebounce } from "use-debounce";
import { Outlet, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import "./style.css";

// api
import { fetchEmployees, fetchOptions } from "../../../api";

// Components
import EmployeesTable from "./EmployeesTable";
import ActionsModal from "./ActionsModal";
import Queue from "./Queue";
import { Button } from "../../../../GlobalComponents";
import {
  SuccessModal,
  Input,
  FilterSelect
} from "../../Components";

// styled components
import { TableContainer, Wrapper } from "../../Components/Styled";

// Context
import Context from "../../../../Context";

const filterOptions: FilterOpt[] = [
  {
    label: "Market",
    key: "Market",
  },
  {
    label: "Name",
    key: "Name",
  },
]


export default function RosterList() {
    const queryClient = useQueryClient();
    const { id } = useParams();
    const { selectedEmployees, setSelectedEmployees, setSelectionModel, selectionModel } = useContext(Context);
    // const [selectedEmployees, setSelectedEmployees] = useState<[]>([]);
    const [actionsModalOpen, setActionsModalOpen] = useState<boolean>(false);
    const [selectedMarket, setSelectedMarket] = useState<string>("");
    const [selectedTeam, setSelectedTeam] = useState<string>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const [debouncedSearchValue] = useDebounce(searchValue, 1000);
    const [filterOption, setFilterOption] = useState<string | null>(null);
    const [filterOptionToDisplay, setFilterOptionToDisplay] = useState<string>("Name");
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
    const [confirmationText, setConfirmationText] = useState<string>("");

    const getFilterValue = () => {
        switch(filterOption) {
        case "team":
            return selectedTeam;
        case "market":
            return selectedMarket;
        case "name":
            return debouncedSearchValue;
        default:
            return null;
        }
    }

    const clearFilter = () => {
      switch(filterOption) {
        case "team":
            setSelectedTeam("");
            break;
        case "market":
            setSelectedMarket("");
            break;
        case "name":
            setSearchValue("");
            break;
        default:
            break;
        }
    }

  const {
    data: employeesData,
    error: employeesError,
    isLoading: employeesLoading,
    refetch: employeesRefetch,
  } = useQuery({
    queryKey: ["employees", filterOption, debouncedSearchValue, selectedMarket],
    queryFn: () => fetchEmployees(filterOption, getFilterValue()),
    enabled: true,
    retry: false,
  });

  const {
    data: optionsData,
    error: optionsError,
    isLoading: optionsLoading,
    refetch: optionsRefetch,
  } = useQuery({
    queryKey: ["options"],
    queryFn: () => fetchOptions(),
    enabled: true,
    retry: false,
  });

  useEffect(() => {
    if (employeesData) {
        console.log("data", employeesData);
    }
  }, [employeesData]);

  const renderFilterOption = () => {
    switch(filterOptionToDisplay) {
      case "Team":
        return (<FilterSelect
              label="Filter by Team"
              itemKey="value"
              options={optionsData?.teams || []}
              value={selectedTeam}
              onSelect={(e: string) => {
                setSelectedTeam(e);
                setFilterOption("team");
              }}
            />)
      case "Market":
        return (
          <FilterSelect
              label="Filter by Market"
              options={optionsData?.markets_filter || []}
              value={selectedMarket}
              itemKey="value"
              onSelect={(e: string) => {
                console.log("EEEE", e);
                setSelectedMarket(e);
                setFilterOption("market");
              }}
              boxStyle={{width: 200}}
            />
        )
      case "Name":
        return (
          <Input
            value={searchValue}
            placeholder="Search by Name"
            onChange={(text) => {
              setSearchValue(text);
              setFilterOption("name");
            }}
          />
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    console.log("selection model", selectionModel);
  },[selectionModel])


  if(id) return <Outlet />

  return (
    <Wrapper>
      <TableContainer>
        <div id="header">
          <div id="title">Search Employee(s)</div>
          <div id="header-tools-container">
            <Button 
              disabled={!getFilterValue()}
              onClick={clearFilter}
            >
              Clear Filter
            </Button>
            {renderFilterOption()}
            <FilterSelect
              label="Select Filter Option"
              options={filterOptions}
              value={filterOptionToDisplay}
              onSelect={(e: string) => {
                setFilterOptionToDisplay(e);
              }}
              bgColor="#fff"
              boxStyle={{marginRight: 5}}
            />
          </div>
        </div>
        {
          employeesError || optionsError ? (
            <div style={{color: "red", fontSize: 16, marginBottom: 10}}>
              There was an error loading the data. Please try again later.
            </div>
          ) : null
        }
        <EmployeesTable
          rows={employeesData?.values || []}
          loading={employeesLoading}
        />
      </TableContainer>
      <Queue
        queueList={selectedEmployees}
        onEdit={() => setActionsModalOpen(true)}
        onRemove={(empId: string) => {
          // remove from employee queue list
          setSelectedEmployees((prev) => {
            return (prev.filter((emp) => {
              return emp.Momentum_id !== empId
            }))
          });

          // remove from MUI datagrid selected list
          setSelectionModel((prev) => {
            return (prev.filter((emp) => {
              console.log("prev emp", emp, "empId", empId, emp !== empId)
              return emp !== empId
            }))
          }
          );
        }}
        onRemoveAll={() => {
          // remove from employee queue list
          setSelectedEmployees([]);
          // remove from MUI datagrid selected list
          setSelectionModel([]);
        }}
      />
      <ActionsModal
        open={actionsModalOpen}
        setOpen={setActionsModalOpen}
        actionSelections={{
          teams: optionsData?.teams || [],
          markets: optionsData?.markets || [],
          departments: optionsData?.departments || [],
          active_status: optionsData?.active_status || [],
          department_groupings: optionsData?.department_groupings || [],
        }}
        selectedEmployees={selectedEmployees}
        onSuccess={() => {
          queryClient.invalidateQueries(["employees"]);
          setConfirmationModalOpen(true);
          setConfirmationText(
            "Your update request has been sent to HR for review."
          );
          setSelectedEmployees([]);
          setSelectionModel([]);
        }}
      />
      <SuccessModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        modalTitleText={confirmationText}
      />
    </Wrapper>
  );
}