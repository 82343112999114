import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  //   align-items: center;
  padding: 20px;
  color: #585858;

  #left-container {
    width: 30%;
  }

  #fileContainer {
  }
  p {
    color: #d5d5d5;
  }
  #title {
    color: ${COLORS.PRIMARY};
    font-family: "Gotham Bold";
  }
  #header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
  }
  #header-tools-container {
    display: flex;
    width: 700px;
    justify-content: space-between;
    align-items: center;
  }
  #table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #table-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
  #table-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
`;

export default TableContainer;