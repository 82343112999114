import React, { createContext, useState, useEffect } from "react";
import { IAccountInfo } from "react-aad-msal";
interface User {
  crm_id: string;
  email: string;
  manager_crm_id: string;
  manager_email: string;
  manager_name: string;
  microsoft_id: string;
  rep_name: string;
  territory: string;
  photo_url: string;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  account: User | null;
  setAccount: React.Dispatch<React.SetStateAction<User | null>>;
  logout: () => void | null;
  setLogout: React.Dispatch<React.SetStateAction<() => void>>;
  authProviderLocal: any;
  setAuthProviderLocal: React.Dispatch<React.SetStateAction<any>>;
  oldPath: string;
  setOldPath: React.Dispatch<React.SetStateAction<string>>;
  access: string;
  setAccess: React.Dispatch<React.SetStateAction<string>>;
  apiTest: number;
  setAPITest: React.Dispatch<React.SetStateAction<number>>;
  azureAccount: IAccountInfo | null;
  setAzureAccount: React.Dispatch<React.SetStateAction<IAccountInfo | null>>;
  isDevAccount: boolean;
  setIsDevAccount: React.Dispatch<React.SetStateAction<boolean>>;
  testEmail: string;
  setTestEmail: React.Dispatch<React.SetStateAction<string>>;
  permissions?: string[];
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthContextProvider = ({ children, ...props }: { children: React.ReactNode}): JSX.Element => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [account, setAccount] = useState<User | null>(null);
  const [logout, setLogout] = useState<any>(null);
  const [azureAccount, setAzureAccount] = useState<IAccountInfo | null>(null);
  const [authProviderLocal, setAuthProviderLocal] = useState<any>(null);
  const [oldPath, setOldPath] = useState("");
  const [access, setAccess] = useState("");
  const [apiTest, setAPITest] = useState<number>(0);
  const [isDevAccount, setIsDevAccount] = useState<boolean>(false);
  const [testEmail, setTestEmail] = useState<string>("");
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    console.log("permissions changed in context", permissions)
  },[permissions]);

  // const fetchPermissions = async (uid: string | undefined) => {
  //   // const response = await fetch(`https://www.google.com`);

  //   const data = {
  //     permissions: ["roster"],
  //   };

  //   setPermissions(data.permissions);
  // };

  // useEffect(() => {
  //   if(azureAccount) {
  //     console.log("azureAccount", azureAccount)
  //     fetchPermissions(azureAccount?.account.userName);
  //   }

  //   if(testEmail) {
  //     console.log("testEmail", testEmail)
  //     fetchPermissions(testEmail);
  //   }
  // }, [azureAccount, testEmail]);

  useEffect(() => {
    console.log("is athenticated changed in context", isAuthenticated)
  },[isAuthenticated])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        account,
        setAccount,
        logout,
        setLogout,
        authProviderLocal,
        setAuthProviderLocal,
        oldPath,
        setOldPath,
        access,
        setAccess,
        setAPITest,
        apiTest,
        azureAccount,
        setAzureAccount,
        isDevAccount,
        setIsDevAccount,
        testEmail, 
        setTestEmail,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
