const officesWithAccess = ['Madrid', 'Barcelona', 'Miami'];

const checkOfficeAccess = (office: string | null): boolean => {
    if (!office) {
        return officesWithAccess.includes(office as string);
    }
    return false;
}

const featureFlags = {
    dashboard: TextTrackCue,
    roster_employees: true,
    roster_teams: true,
    coad_file_uploader: false,
    settings: false,
    announcements: false,
}

const version = "1.0.0"
const versionNotes = {
  "1.0.0": [
    {
      title: "Added Sales Rep Roster feature",
      notes: [
        "Added Roster Table and Queue",
        "Added Roster Filters",
        "Added Roster Search",
        "Added Roster Queue System",
        "Added Single Employee Page",
      ],
    },
    {
      title: "Added HR Change Review Page",
      notes: [
        "Added HR Change Review Table",
        "Added Ability to Approve/Reject HR Changes",
      ],
    },
  ],
  "0.1.1": { title: "Added new feature" },
  "0.1.2": { title: "Fixed bug" },
};

export {
    officesWithAccess,
    checkOfficeAccess,
    featureFlags,
    version,
    versionNotes,
}

