import { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchChangeOrder, fetchOptions, confirmChangeOrder } from "../api";
import ChangesGrid from "./ChangesGrid";
import { Wrapper, Container } from "../../GlobalComponents/Styled";
import { EmployeeInfo } from "../../GlobalComponents";
import AuthContext from "../../Auth/AuthContext";
import Success from "./Success";

export default function ReviewChangeOrder() {
  const { id } = useParams();
  const { azureAccount } = useContext(AuthContext);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { data, isLoading, error } = useQuery(
    ["changes", id],
    () => fetchChangeOrder(id || ""),
    {
      enabled: !!id,
    }
  );

  const {
    data: optionsData,
    error: optionsError,
    isLoading: optionsLoading,
    refetch: optionsRefetch,
  } = useQuery({
    queryKey: ["options"],
    queryFn: () => fetchOptions(),
    enabled: true,
    retry: false,
  });

  const employee = data?.employee_info;

  const onConfirm = () => {
    if (!id)
      return alert(
        "There was an error confirming this change order. Please try again later."
      );
    const body = {
      pending_id: parseInt(id),
      actioned_by: azureAccount?.account?.userName || "",
    };

    mutate(body);
  };

  useEffect(() => {
    if (error) {
      alert(
        "There was an error loading this change order. Please try again later."
      );
    }
  }, [error, optionsError]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const { mutate, isLoading: confirmLoading } = useMutation(
    confirmChangeOrder,
    {
      onSuccess: () => {
        setIsSuccess(true);
      },
      onError: () => {
        alert(
          "There was an error confirming this change order. Please try again later."
        );
      },
    }
  );

  return (
    <Wrapper>
      {isSuccess ? (
        <Success />
      ) : (
        <Container>
          {data === null ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p style={{ fontSize: "1.5em" }}>
                This employee change has been completed.
              </p>
            </div>
          ) : (
            <EmployeeInfo
              headerText="Review Change Request for Employee"
              employee={employee}
              active_status={
                optionsData?.active_status?.find(
                  (opt) => opt.value === employee?.Active_status
                )?.label || "-"
              }
              showBackButton={false}
            >
              <ChangesGrid
                changes={data?.actions}
                onConfirm={onConfirm}
                requester={data?.requested_by || ""}
                isLoading={isLoading || optionsLoading || confirmLoading}
              />
            </EmployeeInfo>
          )}
        </Container>
      )}
    </Wrapper>
  );
}
