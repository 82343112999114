import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { COLORS } from "../../../../Theme";
import { Button } from "../../../../GlobalComponents";
import FilterSelect from "../../Components/FilterSelect";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 200,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  color: COLORS.PRIMARY,
  padding: "30px 0px",
};

export default function ActionsModal({
  open,
  setOpen,
  teamOptions = [],
  employee = null,
  setEmployee,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  teamOptions: any[];
  employee: Employee | null;
  setEmployee: (arg: Employee | null) => void;
}) {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(
    teamOptions.find((team) => team.id === employee?.team_id) || null
  );


  const onSave = () => {
    const constructPayload = () => {
      const actionsArray: any[] = [];

      // we create an actions array to send to the api
      // each action is an object with the employee_id, action, and selection
      actionsArray.push({
        employee_id: employee?.Momentum_id,
        action: "team",
        selection: selectedTeam,
      })
      return actionsArray;
    };

    const payload = constructPayload();
    console.log("payload", payload);
  };

  const onClose = () => {
    // setSelectedChanges([]);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select new team for transfer
        </Typography>
        <ActionsContainer>
          <FilterSelect
            label="Transfer Team"
            options={teamOptions}
            value={selectedTeam?.team_name || ""}
            onSelect={(e: string) => {
              setSelectedTeam(teamOptions.find((team) => team.team_name === e));
            }}
          />
        </ActionsContainer>
        <SaveContainer>
          <Button
            onClick={() => {
              onClose();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </SaveContainer>
      </Box>
    </Modal>
  );
}

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 80%;
  height: 50%;
  justify-content: center;
  align-items: center;
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;
