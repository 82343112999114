import styled from "@emotion/styled";
import { COLORS } from "../Theme";
import { css } from "@emotion/react";

const dynamicStyle = (props: any) =>
  css`
    color: ${props.color || "#fff"};
    background-color: ${props.disabled ? COLORS.GRAY : props.backgroundColor || COLORS.PRIMARY};
    border: ${props.border || "none"};
    padding-top: 4px;
    font-size: ${props.fontSize || "14px"};
    font-family: ${props.fontFamily || "Gotham Bold"};
    cursor: ${props.cursor || "pointer"};
    width: ${props.width || "150px"};
    height: ${props.height || "30px"};
    margin: ${props.margin || "10px"};
    border-radius: ${props.borderRadius || "5px"};
    display: ${props.display || "flex"};
    justify-content: ${props.justifyContent || "center"};
    align-items: ${props.alignItems || "center"};
    flex-direction: ${props.flexDirection || "row"};
    position: ${props.position || "relative"};
    top: ${props.top || "0px"};
    left: ${props.left || "0px"};
    right: ${props.right || "0px"};
    bottom: ${props.bottom || "0px"};
    z-index: ${props.zIndex || "0"};
    box-shadow: ${props.boxShadow || "none"};
    text-align: ${props.textAlign || "center"};
    font-weight: ${props.fontWeight || "normal"};
    text-decoration: ${props.textDecoration || "none"};
    text-transform: ${props.textTransform || "none"};
    border-bottom: ${props.borderBottom || "none"};
    border-top: ${props.borderTop || "none"};
    border-left: ${props.borderLeft || "none"};
    border-right: ${props.borderRight || "none"};
    overflow: ${props.overflow || "none"};
    transition: ${props.transition || "none"};
    &:hover {
      color: ${props.hoverColor || "#fff"};
      background-color: ${props.hoverBackgroundColor || "#D3DFED"};
      border: ${props.hoverBorder || "none"};
      font-size: ${props.hoverFontSize || "14px"};
      cursor: ${props.hoverCursor || "pointer"};
      width: ${props.hoverWidth || "150px"};
      height: ${props.hoverHeight || "30px"};
      margin: ${props.hoverMargin || "10px"};
      border-radius: ${props.hoverBorderRadius || "5px"};
      display: ${props.hoverDisplay || "flex"};
      justify-content: ${props.hoverJustifyContent || "center"};
      align-items: ${props.hoverAlignItems || "center"};
      flex-direction: ${props.hoverFlexDirection || "row"};
      position: ${props.hoverPosition || "relative"};
      top: ${props.hoverTop || "0px"};
      left: ${props.hoverLeft || "0px"};
      right: ${props.hoverRight || "0px"};
      bottom: ${props.hoverBottom || "0px"};
      z-index: ${props.hoverZIndex || "0"};
      box-shadow: ${props.hoverBoxShadow || "none"};
      text-align: ${props.hoverTextAlign || "center"};
      font-weight: ${props.hoverFontWeight || "normal"};
      text-decoration: ${props.hoverTextDecoration || "none"};
      text-transform: ${props.hoverTextTransform || "none"};
      border-bottom: ${props.hoverBorderBottom || "none"};
      border-top: ${props.hoverBorderTop || "none"};
      border-left: ${props.hoverBorderLeft || "none"};
      border-right: ${props.hoverBorderRight || "none"};
      overflow: ${props.hoverOverflow || "none"};
      transition: ${props.hoverTransition || "none"};
    }
  `;

const Button = styled.button`
  ${dynamicStyle};
`;


export default Button;