import React from "react";
import { useQuery } from "@tanstack/react-query";

const checkForS3File = async () => {
    try {
      const file = await fetch("www.google.com")
      const fakeFile = {
        file_url: "",
      }
  
      return fakeFile;
    } catch (error) {
        throw error;
    }
}


export default function useFileQuery() {
  const {
    data,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [
      "checkForS3File",
    ],
    checkForS3File,
    {
      enabled: true,
      retry: false,
      meta: {
        errorMessage: "Error checking for S3 file",
      },
    }
  );

  return {
    data,
    error,
    refetch,
    isLoading,
    existingFile: data?.file_url,
  }
}
