import styled from "@emotion/styled";
import { COLORS } from "../../../Theme";
import { Button } from "../../../GlobalComponents";
import { useNavigate } from "react-router-dom";

// assets
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as PersonIcon } from "../../../assets/PersonIcon.svg";

export const QueueEmployeeCard = ({employee, onRemove}: {
  employee: any;
  onRemove: (id: string) => void;
}) => {

  const navigate = useNavigate();

  const getActiveStatus = (status: string) => {
    switch (status) {
      case "A":
        return "active";
      case "L":
        return "inactive";
      case "P":
        return "inactive";
      case "T":
        return "terminated";
      default:
        return "inactive";
    }
  };

  return (
    <EmployeeCard key={employee.id}>
      <div
        className={`icon-container ${getActiveStatus(employee?.Active_status)}`}
      >
        <PersonIcon width={65} height={65} />
      </div>
      <p style={{ fontWeight: "bold" }}>{employee.Name}</p>
      <Button
        onClick={() => navigate(`/roster/employees/${employee.Momentum_id}`)}
      >
        View
      </Button>
      <div>
        <p>{employee?.Office_Code || "Missing Market"}</p>
        <p>{employee?.Job_Title || "Missing Job Title"}</p>
        <p>{employee?.team_name || "Missing Team"}</p>
      </div>
      <RemoveButton
        id="remove-btn"
        onClick={() => onRemove(employee.Momentum_id)}
      >
        <ClearIcon />
      </RemoveButton>
    </EmployeeCard>
  );
};


const EmployeeCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid ${COLORS.PRIMARY};
  width: 190px;
  height: 230px;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  padding: 10px 0;
  font-size: 14px;
  position: relative;

  .css-ezdwiz {
    margin: 0
  }
  
  p {
    color: #000;
    text-align: center;
    line-height: 8px;
  }

  .icon-container {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  .active {
    border: 3px solid #d1f7d5;
  }

  .inactive {
    border: 3px solid #FFF5E5;
  }

  .terminated {
    border: 3px solid #fcbdbd;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: -3%;
  right: -4%;
  border: none;
  color: ${COLORS.PRIMARY};
  font-size: 20px;
  cursor: pointer;
  // border: 1px solid ${COLORS.PRIMARY};
  border-radius: 50%;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: ${COLORS.PRIMARY};
    color: #fff;
  }
`;