import waldoBodySrc from "../assets/waldo-parts/waldo-body.png";
import waldoHandSrc from "../assets/waldo-parts/waldo-hand.png";

import styled from "@emotion/styled";

export default function WavingWaldo() {
  return (
    <Wrapper>
      <BodyImg src={waldoBodySrc} />
      <HandImg src={waldoHandSrc} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 0.5076;
  overflow: hidden;
`;

const HandImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transform-origin: 22% 21%;
  transform: rotate(-10deg);
  animation: wave 1s ease-in-out alternate-reverse infinite;

  @keyframes wave {
    100% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg);
    }
  }
`;

const BodyImg = styled.img`
  position: relative;
  width: 100%;
  z-index: 200;
`;
